






























































































@import '@styleMixins';

.footer-menu .btn-base {
  @include btn-size(0.8rem, 0.75em);

  margin-right: 0.3em;
  margin-left: 0.3em;

  .v-btn__content {
    @extend .rubik--font;
  }
}
.social-channel {
  height: 32px;
  padding: 0 !important;
  margin: 0 !important;
}
